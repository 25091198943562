<template>
  <div
    id="kyc_"
    class="is-flex">
    <!-- <b>KYC</b>
    <p>Status: {{status}} {{refusedReason}}</p> -->
    <!-- v-if="status === 'Not verified' || TryItAgain" -->
    <div
      v-if="status === 'Not verified' || TryItAgain">
      <div
        style="max-width: 580px;">
        <p
          class="title-kyc text-basier mt-6 mb-5">
          Please upload your file so
          <br>
          we can verify your ID
        </p>
        <span
          class="text-kyc text-nunito">
          Please click on the next box upload the file showing the front and back of your document. The document must be clearly visible, not blurry or pixelated as well as valid and up-to-date.
        </span>
      </div>

      <!-- See files to be sent -->
      <div
        v-if="files.length > 0 && files.length < 3"
        class="my-6">
        <div
          class="is-flex img-file-drop mb-6">
          <!-- <p>front</p> -->
          <img
            v-if="files.length > 0" :src="files[0]"
            alt="Front">

          <!-- <p>back</p> -->
          <img
            v-if="files.length > 1"  :src="files[1]"
            alt="Back">
        </div>

        <!-- C2A OK-->
        <div
          class="is-flex is-justify-content-center">
          <b-button
            v-if="files.length > 1"
            @click="submitKYC"
            class="
              button-primary-extra-medium
            "
            type="">
            Upload documents
          </b-button>
          <div v-if="loader">
            <Loading/>
          </div>
        </div>
      </div>

      <!-- Add files -->
      <section
        v-if="files.length < 2 && status !== 'Verified'"  class="container-file-drop-kyc">

        <div
          class="container is-flex is-flex-direction-column">
          <p
            class="text-basier title-file-drop">
            Please upload the
            <span v-if="files.length === 0">
               FRONT
            </span>
            <span v-if="files.length !== 0">
              BACK
            </span>
            of identity card:
          </p>
          <div class="card bg-white">

            <!-- Preview Image -->
            <img v-if="image" :src="image" alt="card_image">

            <!-- Drop image -->
            <b-field v-if="showFileDrop && !image">
              <b-upload
                v-model="dropFiles"
                multiple
                @change.native="handleImage"
                drag-drop>
                <section class="section">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon
                        icon="upload"
                        size="is-large">
                      </b-icon>
                    </p>
                    <p>Drop your files here or click to upload</p>
                  </div>
                </section>
              </b-upload>
            </b-field>

            <!-- C2A Cancell-->
            <div class="is-flex container-btn-file-drop">
              <b-button
                v-if="image"
                @click="fileCancel"
                class="
                  button-secundary-small
                "
                type="">
                Cancel
              </b-button>

              <!-- C2A OK-->
              <b-button
                v-if="image"
                @click="fileOk"
                class="
                  button-primary-medium
                "
                type="">
                Guardar
              </b-button>
            </div>
          </div>
        </div>
      </section>

      <div
        class="container-content-file-drop">
        <p
          class="text-basier mb-5">
          Main mistakes:
        </p>
        <div
          class="grid-content-file-drop">
          <div
            class="is-flex is-flex-direction-column">
            <img
              class="mb-3"
              src="@/assets/images/money/kyc/kyc1.png"
              alt="">
            <span
              class="text-nunito">
              With flash
            </span>
          </div>
          <div
            class="is-flex is-flex-direction-column">
            <img
              class="mb-3" src="@/assets/images/money/kyc/kyc2.png"
              alt="">
            <span
              class="text-nunito">
              Too blurry
            </span>
          </div>
          <div
            class="is-flex is-flex-direction-column">
            <img
              class="mb-3"
              src="@/assets/images/money/kyc/kyc3.png"
              alt="">
            <span
              class="text-nunito">
              Too dark
            </span>
          </div>
          <div
            class="is-flex is-flex-direction-column">
            <img
              class="mb-3"
              src="@/assets/images/money/kyc/kyc4.png"
              alt="">
            <span
              class="text-nunito">
              Both sides in the same document
            </span>
          </div>
          <div
            class="is-flex is-flex-direction-column">
            <img
              class="mb-3"
              src="@/assets/images/money/kyc/kyc5.png"
              alt="">
            <span
              class="text-nunito">
              Without all the edges visibles
            </span>
          </div>
          <div
            class="is-flex is-flex-direction-column">
            <img
              class="mb-3"
              src="@/assets/images/money/kyc/kyc6.png"
              alt="">
            <span
              class="text-nunito">
              Covered by anything
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- notificatios -->
    <!-- In process -->
    <div
      v-if="status === 'In process'"
      class="notifications-kyc">
      <div>
        <p
          class="text-basier">
          In-process
          <br>
          verification
        </p>
      </div>
      <div
        class="mb-6">
        <img
          src="@/assets/images/money/kyc/not-in-process.svg"
          alt="">
      </div>
      <div>
        <span
          class="text-nunito">
          We are verifying that everything is fine with your documents, it will not take long ✌️
        </span>
      </div>
    </div>
    <!-- Verified -->
    <div
      v-if="status === 'Verified'"
      class="notifications-kyc">
      <div
        class="mb-6">
        <img
          src="@/assets/images/money/kyc/not-success.svg"
          alt="">
      </div>
      <div>
        <p
          class="text-basier">
          Successful identity
          <br> verification!
        </p>
      </div>
      <div>
        <span
          class="text-nunito">
          We recognized you! You can transfer your funds 🤑
        </span>
      </div>
    </div>
    <!-- Refused -->
    <div
      v-if="status === 'Refused' && !TryItAgain"
      class="notifications-kyc">
      <div
        class="mb-6">
        <img
          src="@/assets/images/money/kyc/not-rejected.svg"
          alt="">
      </div>
      <div>
        <p
          class="text-basier">
          Verification
          <br> rejected
        </p>
      </div>
      <div>
        <span
          class="text-nunito">
          Your identification process could not be completed because “{{refusedReason}}”
        </span>
      </div>
      <div
        class="is-flex is-justify-content-center mt-6">
        <button
          @click="TryItAgain = true"
          class="button-primary-extra-medium">
          Try it again
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/modals/loader.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import MpService from '@/services/MpService'

export default {
  components: {
    Loading
  },
  name: 'KYC',

  data () {
    return {
      user: null,
      files: [],
      dropFiles: [],
      image: '',
      status: null,
      refusedReason: null,
      // status_: 'rejected',
      TryItAgain: false,
      loader: false
    }
  },

  // CREATED

  async created () {
    // Listen to data
    this.notify.$on('updateUser', (user) => {
      this.user = user
      this.status = user.verified
      this.refusedReason = user.refusedReason
    })

    await this.notify.$emit('updateData')
  },

  // COMPUTED

  computed: {
    showFileDrop: function () {
      if (this.dropFiles.length > 0) {
        return false
      } else {
        return true
      }
    }

  },

  // METHODS

  methods: {
    // Handle Image
    async handleImage (e) {
      const selectedImage = this.dropFiles[0]
      // const selectedImage = e.target.files[0] // get first file
      this.createBase64Image(selectedImage)
    },

    async createBase64Image (fileObject) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.image = e.target.result
      }
      reader.readAsDataURL(fileObject)
    },

    // file Ok
    async fileOk () {
      this.files.push(this.image)
      this.dropFiles = []
      this.image = ''
    },

    // file cancell
    async fileCancel () {
      this.dropFiles = []
      this.image = ''
    },

    // Submit KYC
    async submitKYC () {
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      try {
        this.loader = true
        const resKYCDocument = await MpService.createKYC(idToken)
        const KYCDocumentId = resKYCDocument.data.Id

        const responseFront = await MpService.addPageKYC(idToken, this.files[0], KYCDocumentId)
        const responseBack = await MpService.addPageKYC(idToken, this.files[1], KYCDocumentId)

        if (responseBack.status === 204 && responseFront.status === 204) {
          const resSubmitKYC = await MpService.submitKYC(idToken, KYCDocumentId)
          // console.log('----> 2201', resSubmitKYC)
          this.notification(resSubmitKYC.data.msg)
          this.loader = false
          this.notify.$emit('refreshKcy')
        } else {
          this.danger('No fue posible subir tus documentos')
          this.loader = false
          this.files.length = 0
          // console.log('----> 1109', responseBack.status, responseFront.status)
        }
      } catch (error) {
        // console.log('Error from submitKYC -->', error)
        this.danger('Hubo un error subiendo tus documentos')
        this.loader = false
        this.files.length = 0
      }
    },

    // Delete file
    deleteDropFile (index) {
      this.dropFiles.splice(index, 1)
    },

    // Notifications

    danger (msg) {
      this.$buefy.toast.open({
        duration: 3000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    },

    notification (msg) {
      this.$buefy.toast.open({
        duration: 1000,
        message: msg,
        position: 'is-top-right',
        type: 'is-success'
      })
    }
  }
}
</script>

<style>
#kyc_ {
  width: 85%;
  margin: 0 auto;
  height: 100%;
}
.title-kyc {
  font-size: 16px;
  font-weight: bold;
}
.img-file-drop {
  gap: 10px;
  justify-content: center;
}
.img-file-drop img{
  width: 150px!important;
}
.text-kyc {
  font-size: 14px;
}
.title-file-drop {
  font-size: 16px;
  font-weight: bold;
}
.container-content-file-drop p {
  font-size: 16px;
  font-weight: bold;
}
.container-content-file-drop {
  max-width: 420px;
  margin: 40px auto;
  padding-bottom: 75px;
}
.grid-content-file-drop {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 100px;
}
.grid-content-file-drop span {
  font-size: 12px;
}
.container-btn-file-drop {
  gap: 8px;
  justify-content: center;
}
.container-file-drop-kyc {
  margin-top: 43px;
}
#kyc_ body {
  background: #d8dddb;
}
#kyc_ .container  {
  display: flex;
  justify-content: center;
}
#kyc_ .bg-white {
  background: #fff;
}
#kyc_ .card {
  padding: 20px;
  text-align: center;
  box-shadow: none!important;
}
#kyc_ .upload .upload-draggable {
  border: 1px solid #b5b5b5;
  border-radius: 2px;
  padding: 15px;
}
#kyc_ .upload .upload-draggable .section {position:relative}
#kyc_ .upload .upload-draggable .section:before, #kyc_ .upload .upload-draggable .section:after, #kyc_ .upload .upload-draggable .section>:first-child:before, #kyc_ .upload .upload-draggable .section>:first-child:after {
  position:absolute; content:' ';
  width:30px; height: 30px;
  border-color:#6E3DEA; /* or whatever colour */
  border-style:solid; /* or whatever style */
}
#kyc_ .upload .upload-draggable .section:before {top:0;left:0;border-width: 3px 0 0 3px}
#kyc_ .upload .upload-draggable .section:after {top:0;right:0;border-width: 3px 3px 0 0}
#kyc_ .upload .upload-draggable .section>:first-child:before {bottom:0;right:0;border-width: 0 3px 3px 0}
#kyc_ .upload .upload-draggable .section>:first-child:after {bottom:0;left:0;border-width: 0 0 3px 3px}
#kyc_ img {
  width: 17rem;
}

/* notifications */
.notifications-kyc {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  /* justify-content: center; */
}
.notifications-kyc p{
  font-weight: 500;
  font-size: 26.0714px;
  color: #0C0D34;
  margin-bottom: 65px;
}
.notifications-kyc span{
  font-weight: 300;
  font-size: 14px;
  color: #393939;
}

@media (min-width: 768px) {
  .container-file-drop-kyc {
    margin-top: 85px;
  }
  .title-file-drop {
    width: 420px;
    margin: 0 auto;
  }
}
</style>
