<template>
  <div
    class="back-ground-dark">
    <div class="">
      <div
        class="container-balance">
        <div class="balance_total_desktop">
          <div
            class="balance_total">
            <span
              class="text-basier">
              Mi Saldo
            </span>
            <p
              class="text-basier-bold total_balance_wallet">
              {{balance/100}} {{currency}}
            </p>
            <!-- <p
              class="text-basier-medium  available-balance">
              Available: {{(balance/100)-(pending/100)}} {{currency}}
            </p> -->
          </div>
          <div
            class="btn_balance">
            <button
              @click="clickWithdrawFunds"
              :class="this.bankAccounts.length === 0 ? 'disabled-btn' : ''"
              class="text-basier  button-primary-abogadus">
              <span
                class="text-basier-medium">
                Retirar fondos
              </span>
            </button>
          </div>
        </div>
      </div>
      <div
        class="history_balance">
        <p
          class="text-basier text_history">
          Transacciones
        </p>

        <ul>
          <li
            v-for="(transaction, i) in transactions" :key="i"
            class="is-flex is-justify-content-space-between">
            <div
              class="content_inf_balance">
              <div>
                <p
                  class="text-basier mb-3 white">
                  {{transaction.type}}
                </p>
                <span
                  class="text-nunito">
                  {{transaction.tag}}
                </span>
              </div>
              <div
                class="content_date_balance">
              <p
                class="mt-3 text-nunito color_orange">
                {{(transaction.amount / 100).toFixed(2)}} {{currency === 'USD' ? '$' : '€'}}
                <!-- class="text-basier mt-2">
                -- -->
              </p>
              <p
                class="mt-2 text-nunito-Regular dark-gray-emitus">
                {{transaction.date}} {{transaction.time}}
              </p>
              <!-- <p
                class="mt-3 text-nunito color_green">
                {{(transaction.amount / 100).toFixed(2)}} {{currency === 'USD' ? '$' : '€'}}
                class="mt-2 text-nunito-Regular dark-gray-emitus">
                {{transaction.date}} {{transaction.time}}
              </p> -->
              </div>
            </div>

            <div
              class="content_amount_balance is-flex">
              <div
                v-if="!transaction.credit"
                class="info_amount_balance">
                <img
                  src="@/assets/images/money/arrow_orange.svg"
                  alt="">
                <p
                  class="mt-3 text-nunito color_orange">
                  {{(transaction.amount / 100).toFixed(2)}} {{currency === 'USD' ? '$' : '€'}}
                </p>
              </div>
              <div
                v-if="transaction.credit"
                class="info_amount_balance">
                <img
                  src="@/assets/images/money/arrow_green.svg"
                  alt="">
                <p
                  class="mt-3 text-nunito color_green">
                  {{(transaction.amount / 100).toFixed(2)}} {{currency === 'USD' ? '$' : '€'}}
                </p>
              </div>
              <div
                class="content_total_balance">
                <span
                  class="text-basier">
                  TOTAL
                </span>
                <p
                  class="text-basier">
                  {{(transaction.total / 100).toFixed(2)}} {{currency === 'USD' ? '$' : '€'}}
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="withdraw-funds" v-if="showWithdrawFunds">
        <div class="cancel-withdraw-funds">
          <img @click="clickHiddeWithdrawFunds" src="@/assets/images/onlineSession/cancel_modal.svg" alt="">
        </div>
        <div>
          <WithdrawFunds :key="refreshBankAccounts"/>
        </div>
      </div>
    </div>
    <!-- v-if="modalActivedOnlineSessions" -->
    <div v-if="modalActivedOnlineSessions" class="not-online-sessions is-flex is-justify-content-center is-align-items-center">
      <div class="is-flex is-justify-content-center is-flex-direction-column has-text-centered is-align-items-center">
        <img src="@/assets/images/money/kyc/not-in-process.svg" alt="">
        <!-- <p class="text-basier my-5" style="font-weight: bold;">Online Sessions</p> -->
        <br><br>
        <span class="text-basier text-modal" style="font-weight: 300;">Make your profile available and start geting paid for online sessions5. <br><br> You can turn it on/off whenever you want. </span>
        <button class="button-primary-extra-medium text-basier mt-6" @click="actOnlineSessions">Active Online Session</button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import MpService from '@/services/MpService'
import WithdrawFunds from '@/components/money/WithdrawFunds'
import UserService from '@/services/UserService'
import CalendarService from '@/services/CalendarService'

export default {
  name: 'Money',
  components: {
    WithdrawFunds
  },
  // DATA
  data () {
    return {
      user: {},
      currency: null,
      currency2: '€',
      balance: null,
      pending: null,
      num: 3000,
      transactions: null,
      bankAccounts: [],
      showWithdrawFunds: false,
      refreshBankAccounts: 0,
      modalActivedOnlineSessions: null
    }
  },
  metaInfo: {
    title: 'emitus | Profile'
  },

  // CREATED
  async created () {
    this.notify.$on('refreshBankAccount', () => {
      this.refreshBankAccounts++
    })
    // Listen to data
    this.notify.$on('updateUser', (user) => {
      this.user = user
      this.pending = user.mangopaywallet.pending
    })
    // this.notify.$on('bankAccountsArr', (bankAccounts) => {
    //   this.bankAccounts = bankAccounts
    //   console.log(this.bankAccounts, '<<<<')
    // })

    await this.notify.$emit('updateData')

    const idToken = await firebase.auth().currentUser.getIdToken(true)
    MpService.getMyWallet(idToken)
      .then(wallet => {
        this.currency = wallet.data.Balance.Currency
        this.balance = wallet.data.Balance.Amount
      })
      .catch(error => {
        // this.danger(error.response.data.msg)
        if (error.response.data.msg === 'Lo sentimos, aún no tienes una billetera') {
          this.modalActivedOnlineSessions = true
        } else {
          this.danger(error.response.data.msg)
        }
      })

    MpService.getMyTransactions(idToken)
      .then(transactions => {
        this.transactions = transactions.data
      })
      // .catch(error => {
      //   this.danger(error.response.data.msg)
      // })
    MpService.getMyBankAccounts(idToken)
      .then(BankAccountsArr => {
        this.bankAccounts = BankAccountsArr.data
      })
  },

  // MOUNTED
  async mounted () {
    // Ask for data to App
    await this.notify.$emit('giveMeData')
    // document.getElementById('fc_frame').style.display = 'none'
  },

  // METHODS
  methods: {
    // Notifications
    danger (msg) {
      this.$buefy.toast.open({
        duration: 3000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    },
    notification () {
      this.$buefy.toast.open({
        duration: 1000,
        message: 'Guardado',
        position: 'is-top-right',
        type: 'is-success'
      })
    },

    // withdraw funds
    clickWithdrawFunds () {
      if (this.bankAccounts.length === 0) {
        // this.notify.$emit('notificationWhenNotAccount')
        this.$router.push('/panel/me/edit-me?section=bank')
      } else {
        this.showWithdrawFunds = true
        const publicProfile = document.body
        publicProfile.classList.toggle('noScroll')
        // this.$router.push('/panel/money/withdraw-funds')
      }
    },
    clickHiddeWithdrawFunds () {
      this.showWithdrawFunds = false
      const publicProfile = document.body
      publicProfile.classList.toggle('noScroll')
    },
    async actOnlineSessions () {
      await this.createCalendar()
      this.modalActivedOnlineSessions = false
      firebase.auth().currentUser.getIdToken(true)
        .then(async idToken => {
          await UserService.updateSessions(idToken, true)
          this.notify.$emit('updateData')
        })
        .catch(error => {
          console.log('Error updating monetization sessions-online', error)
        })
    },
    // Create Calendar -------
    async createCalendar () {
      try {
        const idToken = await firebase.auth().currentUser.getIdToken(true)
        const response = await CalendarService.createMyCalendar(idToken)
        // console.log(response, 'esto awui')
        this.notificationCalendar('Calendar created successfully', response)
      } catch (error) {
        this.notificationCalendarErr(error, 'error al crear el calendario')
      }
    },
    // Calendar Notifications
    notificationCalendarErr (err) {
      this.$buefy.toast.open({
        duration: 5000,
        message: err,
        position: 'is-top',
        type: 'is-danger'
      })
    },
    notificationCalendar (msg, response) {
      this.$buefy.toast.open({
        duration: 5000,
        message: msg,
        position: 'is-top',
        type: 'is-success'
      })
    }
  },

  // DESTROY
  destroyed () {
    // document.getElementById('fc_frame').style.display = 'block'
  }
}
</script>

<style scoped>
.disabled-btn {
  cursor: not-allowed;
  opacity: 0.5;
}
.not-online-sessions {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 4;
}

.back-ground-dark {
  width: 100%;
  height: 100vh;
  background-color: #000;
  overflow: auto;
  padding-bottom: 180px;
}

.cancel-withdraw-funds {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 10;
}
.withdraw-funds {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 11;
  overflow: auto;
}

.container-balance {
  padding-top: 120px;
}

.balance_total {
  text-align: center;
}
.balance_total span {
  font-size: 13px;
  color: #999999;
  font-weight: normal;
}
.total_balance_wallet {
  /*font-weight: 600;*/
  font-size: 35px;
  color: #fff;
}

.available-balance {
  font-size: 15px;
  color: #00C1B1;
}
.history_balance {
  width: 100%;
  margin: 44px auto;
  max-width: 709px;
}
.text_history {
  margin-bottom: 10px;
}
.history_balance p {
  font-weight: normal;
  font-size: 12.5px;
  color: #9fe7b7;
}
.history_balance ul li {
  border-top: 1px solid rgba(238, 238, 238, 0.5);
  padding: 17px 0px 19px 0px
}
.content_inf_balance {
  width: 135px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.content_inf_balance p {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.8);
}
.content_inf_balance span {
  font-weight: 200;
  font-size: 12px;
  color: #929292;
}
.content_date_balance p {
  font-weight: 200;
  font-size: 12.6px;
  color: #ffffffb3;
}
.content_amount_balance {
  gap: 34px;
}
.content_amount_balance img {
  width: 10px;
  height: 10px;
}
.content_amount_balance p {
  font-weight: 600;
  font-size: 13px;
}
.color_orange {
  color: #FE5E33!important;
}
.color_green {
  color: #2CB9B0!important;
}
.info_amount_balance {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content_total_balance span {
  font-weight: normal;
  font-size: 10px;
  color: #fff;
}
.content_total_balance {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.content_total_balance p {
  font-weight: 600;
  font-size: 12px;
  margin-top: 6px;
  color: #fff;
}

@media (min-width: 600px) {
   .content_inf_balance {
    width: 350px;
    flex-direction: row;
  }
}
@media (max-width: 1024px) {

  .history_balance {
    width: 85%;
    max-width: 550px;
    margin: 50px auto;
  }

  .btn_balance button {
    width: 160px;
    bottom: 79px;
    right: 15px;
    position: fixed;
  }
}
@media (min-width: 1024px) {

   .content_inf_balance {
    width: 485px;
    flex-direction: row;
  }

  .btn_balance button {
    position: static;
    box-shadow: none;
    top: 220px;
  }

  .history_balance {
    margin: 52px auto;
  }
  .balance_total {
    text-align: left;
    margin-bottom: 20px;
    margin-top: 31px;
  }
  .text_history {
    margin-bottom: 37px;
  }
  .balance_total_desktop {
    display: flex;
    width: 80%;
    max-width: 800px;
    justify-content: space-between;
    align-items: center;
  }
  .container-balance {
    width: 100%;
    padding-top: 150px;
    margin: 4px auto 25pxpx auto;
    display: flex;
    justify-content: center;
    box-shadow: 0px 13px 20px rgba(0, 0, 0, 0.03);
    padding-bottom: 20px;
  }

}
</style>
