<template :key="refreshWithdrawFunds">
  <div id="Withdraw_founds">
    <div class="container">
      <h1 class="text-basier">Withdraw <br> Funds</h1>
      <p class="description-nunito dark-gray-emitus">
        This is where we send you the money from
        <br> your completed sessions.
      </p>
      <div class="content_withdraw_funds">

        <form id="bank_form_modal" class="bank_form mt-6">
          <p
            class="sub-title-input text-basier-medium dark-blue-emitus">
            Amount to withdraw
          </p>
          <!-- Amount -->
          <b-field
            :message="messageAmount"
            :type="messageTypeAmount"
            class="name"
          >
            <b-input
              id="Amount"
              v-model="Amount"
              autocomplete="off"
              placeholder="0"
              type="number"
              v-on:focusout.native="ValidateAmount"
              v-on:focusin.native="clearValidateAmount"
            >
            </b-input>
          </b-field>

          <!--- BankAccount-->

          <p
            class="mt-6 sub-title-input text-basier-medium dark-blue-emitus">
            Account to transfer
          </p>
          <div :key="formAccounts">
            <b-field
              v-for="(bankAccount, idx) in bankAccounts"
              :key="idx"
              class="is-flex is-justify-content-space-between mt-6 pb-5"
              style="max-width: 416px; border-bottom: 1px solid #ECECEC;"
            >
              <b-radio
                type="radio"
                v-model="BankAccount"
                name="account"
                :native-value="bankAccount">

                <!--container bank account -->

                <div
                  class="is-flex is-align-items-center"
                  style="gap: 15px;">
                  <div class="is-flex">
                    <img src="@/assets/images/panel/me/bank.png" alt="">
                  </div>
                  <div
                    class="ml-1">
                    <p
                      class="text-basier dark-blue-emitus title-bank-account">
                      BANK ACCOUNT
                    </p>
                    <span
                      class="is-flex is-align-items-center">
                      {{bankAccount.show + ' ' + bankAccount.payoutCost}}
                    </span>
                  </div>
                </div>
              </b-radio>
              <div class="button-delete-account-whitdraw">
                <a
                  class="btnDelete text-basier dark-purple-emitus link-text-purple"
                  style="padding-left: 0px;"
                  @click="deleteBankAccount(bankAccount.BankAccountId)"
                >
                  Delete account
                </a>
              </div>
            </b-field>
          </div>

          <div
            class="mt-5 pt-2 pl-2">
            <a
              class="purple-dark-emitus text-basier link-text-purple"
              @click="clickBankDetails">
              Manage Bank Accounts
            </a>
          </div>

          <!-- CTA -->
          <div class="mt-6 mb-6 pt-5">
            <button
              class="text-basier icon-button-medium"
              @click.prevent="createPayout"
            >Transfer</button>
          </div>
        </form>

      </div>
    </div>
    <!-- modal kyc -->
    <div v-if="modalKyc" class="modal_fixed_kyc is-flex is-flex-direction-column">
      <div @click="clickHiddeKyc" class="cancel_modal_kyc">
        <img src="@/assets/images/money/kyc/cancel.svg" alt="">
      </div>
      <div style="height: 100%;">
        <Kyc :key="kycKey"/>
      </div>
    </div>
  </div>
</template>

<script>
import Kyc from '@/components/money/kyc.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import MpService from '@/services/MpService'
export default {
  components: {
    Kyc
  },
  data () {
    return {
      formAccounts: 0,
      modalWithdrawFunds: false,
      modalKyc: false,
      kycKey: 0,
      // To be sent
      Amount: null,
      BankAccount: null,
      Currency: 'EUR',
      PayoutModeRequested: 'STANDARD',

      // Messages
      messageBankAccount: '',
      messageTypeBankAccount: '',
      messageAmount: '',
      messageTypeAmount: '',

      // About data
      locale: undefined, // Browser locale,

      bankAccounts: [],
      user: {},
      refreshWithdrawFunds: 0,
      // To be sent
      AddressLine1: null,
      City: null,
      Region: null,
      PostalCode: null,
      Country: null,
      IBAN: null,

      // Messages
      messageCountry: '',
      messageTypeCountry: '',
      messageIBAN: '',
      messageTypeIBAN: '',
      messageAddressLine1: '',
      messageTypeAddressLine1: '',
      messageRegion: '',
      messageTypeRegion: '',
      messagePostalCode: '',
      messageTypePostalCode: '',
      messageCity: '',
      messageTypeCity: '',

      obj_: null,
      formBankAccount: false,
      ShowBankAccountKey: 0,

      // Operations
      valid: true
    }
  },
  async created () {
    this.notify.$on('refreshKcy', async () => {
      this.kycKey++
    })
    const idToken = await firebase.auth().currentUser.getIdToken(true)
    const myBankAccounts = await MpService.getMyBankAccounts(idToken)
    for (let i = 0; i < myBankAccounts.data.length; i++) {
      const elem = myBankAccounts.data[i]
      const obj = {
        show: elem.IBAN_A + '...' + elem.IBAN_B,
        BankAccountId: elem.BankAccountId,
        payoutCost: elem.payoutCost
      }
      this.bankAccounts.push(obj)
    }
    this.notify.$on('updateUser', (user) => {
      this.user = user
    })

    this.notify.$emit('giveMeData')
  },
  methods: {
    // bank details
    clickBankDetails () {
      const publicProfile = document.body
      publicProfile.classList.remove('noScroll')
      this.notify.$emit('changeSectionAvatarBank', this.bank)
      this.$router.push('/panel/me/edit-me?section=bank')
    },

    // deleteBankAccount
    async deleteBankAccount (BankAccountId) {
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      try {
        const response = await MpService.deleteBankAccount(idToken, BankAccountId)
        if (response.data.Active === false) {
          this.notification('Cuenta bancaria eliminada')
          this.notify.$emit('refreshBankAccount')
        }
      } catch (error) {
        console.log('Error from deleteBankAccount -->', error)
      }
    },
    // Create Bank Account IBAN
    async createIBANBankAccount () {
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      const obj = {
        AddressLine1: this.AddressLine1,
        City: this.City,
        Region: this.Region,
        PostalCode: this.PostalCode,
        Country: this.Country,
        IBAN: this.IBAN
      }
      try {
        const response = await MpService.createIBANBankAccount(idToken, obj)
        if (response.data.bankAccount.Active) {
          this.notification(`Tarjeta ${response.data.bankAccount.IBAN} guardada`)
          this.modalWithdrawFunds = false
          this.notify.$emit('refreshBankAccount')
        }
      } catch (error) {
        console.log('Error from createIBANBankAccount -->', error)
      }
    },
    // Validations

    clickShowModalWithdraw () {
      this.modalWithdrawFunds = true
    },
    clickHiddeModalWithdraw () {
      this.modalWithdrawFunds = false
    },
    clickShowKyc () {
      this.modalKyc = true
      const publicProfile = document.body
      publicProfile.classList.toggle('noScroll')
    },
    clickHiddeKyc () {
      this.modalKyc = false
      const publicProfile = document.body
      publicProfile.classList.toggle('noScroll')
    },
    // Create Bank Account Amount
    async createPayout () {
      if (this.Amount !== null && this.BankAccount !== null) {
        const idToken = await firebase.auth().currentUser.getIdToken(true)

        if (this.user.verified === 'Verified') {
          try {
            const obj = {
              Amount: this.Amount,
              Currency: this.Currency,
              BankAccountId: this.BankAccount.BankAccountId,
              Tag: `Transfer to bank account ${this.BankAccount.show}`,
              PayoutModeRequested: this.PayoutModeRequested
            }
            const response = await MpService.createPayout(idToken, obj)
            if (response.data.Status === 'CREATED') {
              this.notification('Desembolso exitoso')
            } else {
              this.danger('Desembolso fallido')
            }
          } catch (error) {
            console.log('Error from createAmountBankAccount -->', error)
            this.danger('Cumplimenta los campos')
          }
        } else {
          this.modalKyc = true
          const publicProfile = document.body
          publicProfile.classList.toggle('noScroll')
        }
      } else {
        this.danger('Completa los campos')
      }
    },

    // Validations
    ValidateBankAccount () {
      if (!this.BankAccount) {
        this.messageBankAccount = 'Te falta este campo'
        this.messageTypeBankAccount = 'is-primary'
        document.getElementById('BankAccount').classList.add('invalid-input')
        return false
      } else {
        this.messageBankAccount = ''
        this.messageTypeBankAccount = ''
        return true
      }
    },
    ValidateAmount () {
      if (!this.Amount) {
        this.messageAmount = 'Te falta este campo'
        this.messageTypeAmount = 'is-primary'
        document.getElementById('Amount').classList.add('invalid-input')
        return false
      } else {
        this.messageAmount = ''
        this.messageTypeAmount = ''
        return true
      }
    },
    // Notifications
    danger (msg) {
      this.$buefy.toast.open({
        duration: 3000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    },
    notification (msg) {
      this.$buefy.toast.open({
        duration: 1000,
        message: msg,
        position: 'is-top-right',
        type: 'is-success'
      })
    },

    // Clear Validations
    clearValidateBankAccount () {
      this.messageBankAccount = ''
      this.messageTypeBankAccount = ''
      document.getElementById('BankAccount').classList.remove('invalid-input')
    },
    clearValidateAmount () {
      this.messageAmount = ''
      this.messageTypeAmount = ''
      document.getElementById('Amount').classList.remove('invalid-input')
    }
  },
  beforeDestroy () {
    this.notify.$off('refreshKcy')
  }
}
</script>

<style>

#bank_form_modal .label:not(:last-child) {
  color: #363636!important;
}

#bank_form_modal input::placeholder {
  color: #363636!important;
}

.button-delete-account-whitdraw {
  margin-top: -6px;
}

.btnDelete {
  font-size: 12.5px;
}

.sub-title-input {
  font-size: 13px;
}

.title-bank-account {
  margin-bottom: 7px;
  font-size: 13px;
  color: #0D1239
}

.content_withdraw_funds {
  margin-bottom: 60px;
}
.content_withdraw_funds .control {
  max-width: 416px;
  margin-top: 8px;
}
.container {
  width: 85%;
  max-width: 450px;
  margin: 117px auto 102px auto;
}
.container h1 {
  font-weight: normal;
  font-size: 32px;
  color: #0D1239;
  margin-bottom: 22px;
}

.content_withdraw_funds label {
  font-weight: bold;
  font-size: 14.1667px;
  color: #111747;
}
.content_withdraw_funds label span {
  font-weight: 500;
  font-size: 14.1667px;
  color: #999999;
}
/* modal */
.modal_fixed {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_fixed_kyc {
  background: #ffffff;
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 20;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  overflow: auto;
}
.modal_withdraw_funds {
  background-color: rgba(13, 18, 57, 0.87);
  height: 100%;
  width: 100%;
}
.container_modal_withdraw_funds {
  background: #ffffff;
  width: 85%;
  height: 80%;
  position: fixed;
  /* top: 0; */
  z-index: 21;
  margin-bottom: 60px;
  overflow: auto;
}
.cancel_modal_withdraw_funds {
  text-align: end;
  position: sticky;
  top: 0;
}
.cancel_modal_withdraw_funds img {
  width: 22px;
  height: 22px;
  margin: 30px 34px 0 0;
}
.cancel_modal_kyc {
  width: 100%;
  text-align: end;
}
.cancel_modal_kyc img{
  margin-top: 25px;
  margin-right: 25px;
}
.container_modal_withdraw_funds .control {
  margin: 6px 0 41px 0;
}
@media (min-width: 1024px) {
  .container {
    margin: 187px auto 102px auto;
    width: 60%;
  }
  .container_modal_withdraw_funds {
    width: 60%;
    height: 75%;
    margin-bottom: 0px;
  }
  .w-desktop-bankAccount {
    /* width: 60%; */
    margin: 0px auto;
  }
  .cancel_modal_kyc img{
    margin-top: 45px;
    margin-right: 100px;
  }
}
</style>
