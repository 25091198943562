import axios from 'axios'

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  async getBlocksByWeek (professionaId, week) {
    return await apiClient.get(`/calendars/${professionaId}/week/${week}`)
  },
  async createMyCalendar (idToken) {
    // console.log('gagaga', idToken)
    return await apiClient.post('/calendars', {},
      {
        headers: { idToken: idToken }
      })
  },
  async updateMyCalendar (idToken, mondayBlocks, tuesdayBlocks, wednesdayBlocks, thursdayBlocks, fridayBlocks, saturdayBlocks, sundayBlocks) {
    // console.log('---> mondayBlocks', mondayBlocks)
    return await apiClient.post('/calendars/current',
      {
        1: mondayBlocks,
        2: tuesdayBlocks,
        3: wednesdayBlocks,
        4: thursdayBlocks,
        5: fridayBlocks,
        6: saturdayBlocks,
        7: sundayBlocks
      },
      {
        headers: { idToken: idToken }
      })
  },
  async getMyCalendar (idToken) {
    return await apiClient.get('/calendars/current',
      {
        headers: { idToken: idToken }
      })
  }
}
